import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"
import { getEBooksCoverUrl } from "utils/rsc-util"

const eBooks = [
  {
    title: "JS Async Programlama",
    url: "https://learnreactui.dev/contents/javascript-async-programlama",
    img: getEBooksCoverUrl("034-js-async-programlama"),
  },
]

export const LinkMap = {
  "Async JS Training": "42c05667f54a",

  "Non-Blocking I/O": "5cae251a0b90",
  "Concurrency vs Paralel": "5cae251a0b90",
  "CPU Scheduling": "5cae251a0b90",
  "Process vs Thread": "5cae251a0b90",
  "Sync vs Async": "5cae251a0b90",

  Callback: "f76ca77a6ce5",
  "Callback Hell": "f76ca77a6ce5",
  "Caolan/Async": "f76ca77a6ce5",
  "Event Emitter": "f76ca77a6ce5",

  "Stack vs Heap": "1986a06b0f94",
  "Call Stack": "1986a06b0f94",
  "Event Loop": "1986a06b0f94",

  Promise: "3aa01c4d4807",
  "Async/Await": "3aa01c4d4807",

  Monad: "ea93fe8d7698",
  "Monad-Promise": "ea93fe8d7698",
  "List Monad": "ea93fe8d7698",
  "I/O Monad": "ea93fe8d7698",

  Generator: "403e33d3a6f1",

  "Saga Pattern": "55329a5f04cf",
  ACID: "55329a5f04cf",
  "CAP Theory": "55329a5f04cf",
  "Two Phase Commit": "55329a5f04cf",
  Saga: "55329a5f04cf",

  "Saga (Generator)": "15ec4dde2dc0",
  "Redux-Thunk": "15ec4dde2dc0",
  "Redux-Saga": "15ec4dde2dc0",
  takeEvery: "15ec4dde2dc0",
  takeLatest: "15ec4dde2dc0",
}

const stories = [
  {
    title: "JS ile Async Programlama Eğitim İçeriği",
    postId: "42c05667f54a",
  },
  {
    title: "Promise-1 (Non-Blocking I/O)",
    postId: "5cae251a0b90",
  },
  {
    title: "Promise-2 (Callback, Async, EventEmiter)",
    postId: "f76ca77a6ce5",
  },
  {
    title: "Promise-3 (Event Loop)",
    postId: "1986a06b0f94",
  },
  {
    title: "Promise-4 (Promise Kavramı)",
    postId: "3aa01c4d4807",
  },

  {
    title: "Generators",
    postId: "403e33d3a6f1",
  },
  {
    title: "Streams",
    postId: "eb2e671cd2c1",
  },
  {
    title: "Web API Async",
    postId: "302f7c38d457",
  },
]

const storiesExtra = [
  {
    title: "Monad Nedir (Promise Altyapısı)",
    postId: "ea93fe8d7698",
  },
  {
    title: "Saga Örüntüsü",
    postId: "55329a5f04cf",
  },
  {
    title: "Saga Örüntüsü (Generator Kullanımı)",
    postId: "15ec4dde2dc0",
  },
]

const storiesGroup = [
  {
    title: "JS Async Programming",
    storyPart: stories,
  },
  {
    title: "JS Async Programming Extra",
    storyPart: storiesExtra,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "JS Async Programming",
  path: "javascript-async-programming",
}

const JSAsyncProgrammingPage = () => {
  return (
    <StoryGroup
      eBooks={eBooks}
      complex={true}
      title={context.title}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default JSAsyncProgrammingPage
